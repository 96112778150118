import React, {useEffect, useRef} from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { colorLightGray, colorWhite } from '../components/Variables/Variables'
import { Wrapper } from '../components/Wrapper'
import { Row, Col } from '../components/Layout/Grid'
import { media } from '../components/Utilities/Media'
import Button from '../components/Button/Btn'
import ContactBg from '../images/contact-temp.jpg'
import { Power4, TweenMax} from 'gsap'
import CSSPlugin from 'gsap/CSSPlugin';

const C = CSSPlugin;

const ContactStyled = styled.div`
  .contact-row {
      margin-bottom: 30px;
      > div {
          flex-basis: 50%;
          &:first-of-type {
              @media ${media.md} {
                  margin-top: 60px;
                  padding-right: 30px;
              }
          }
      }
      p {
          line-height: 1.7;
      }
  }
`
const ContactHeader = styled.div`
    position: relative;
    canvas {
        height: 320px;
        background: url(${ContactBg}) center/cover no-repeat;
        width: 100%;
    }
    h1 {
        position: absolute;
        font-size: 64px;
        top: 50%;
        transform: translateY(-50%);
        color: white;
    }
`

const ContactBody = styled.div`
    ul {
        margin-top: 30px;
        li {
            margin-bottom: 20px;
            .fa {
                margin-right: 20px;
                width: 15px;
            }
        }
    }
`

const FormRow = styled.div`
    &:not(:first-of-type) {
        margin-top: 25px;
    }
`

const ContactForm = styled.form`
    position: relative;
    background-color: ${colorWhite};
    padding: 25px;
    box-shadow: 0 12px 25px 0 rgba(23,39,53,.16);
    border-radius: 8px;
    input,button,textarea {
        display: block;
        width: 100%;
        padding: 10px;
    }
    input {
        background: none;
    }
    button {
        height: 50px;
    }
    .form-control {
        height: 50px;
        border: 1px solid ${colorLightGray};
        &.textbox {
            height: auto;
            resize: none;
        }
    }
    label {
        margin-bottom: 8px;
        display: inline-block;
        font-size: 14px;
    }
`

const ContactPage = () => {
    let formRef = useRef(null);
    useEffect(() => {
        TweenMax.to(formRef,1.5,{
            opacity: 1,
            y: -50,
            ease: Power4.easeOut,
            delay: .3
        })
    }, []);
    return (
        <>
            <Layout>
                <ContactStyled>
                    <ContactHeader>
                        <canvas></canvas>
                        <Wrapper>
                            <h1>Contact Us</h1>
                        </Wrapper>
                    </ContactHeader>
                    <ContactBody>
                        <Wrapper>
                            <Row cname="contact-row">
                                <Col>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <br/>
                                    <p>Co., Ltd. Synergy Solutions Global
                                        (Ltd. Global Synergy Solutions)
                                        on registration, English notation
                                        shown: GSS</p><br />
                                    <p>If you are interested in recruiting or recruiting media, please contact us. We also accept direct inquiries from
                                    job seekers.
                                    </p>
                                    <ul>
                                        <li><i className="fa fa-location-arrow" aria-hidden="true"></i>144 2/f Cocofed bldg. Amorsolo Street Makati City</li>
                                        <li><i className="fa fa-envelope" aria-hidden="true"></i>info@gss-c.biz</li>
                                    </ul>
                                </Col>
                                <Col ref={elem => formRef = elem}>
                                    <ContactForm>
                                        <FormRow>
                                            <label>Full Name</label>
                                            <input name="fullName" className="form-control"/>
                                        </FormRow>
                                        <FormRow>
                                            <label>Email Address</label>
                                            <input name="email" className="form-control"/>
                                        </FormRow>
                                        <FormRow>
                                            <label>Subject</label>
                                            <input name="subject" className="form-control"/>
                                        </FormRow>
                                        <FormRow>
                                            <label>Message</label>
                                            <textarea rows="10" name="message" className="form-control textbox"/>
                                        </FormRow>
                                        <FormRow>
                                            <Button primary>Submit</Button>
                                        </FormRow>
                                    </ContactForm>
                                </Col>
                            </Row>
                        </Wrapper>
                    </ContactBody>
                </ContactStyled>
            </Layout>
        </>
    )
}

export default ContactPage;